import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  //height:650px;
  justify-content: center;
  @media (max-width: 900px) {
    height: auto;
  }
`;

const Subcontainer = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;

  @media (max-width: 900px) {
    width: calc(100% - 60px);
    flex-direction: column-reverse;
  }
`;
const Leftcontainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0px 3%;
  @media (max-width: 900px) {
    width: 100%;
    padding: 0px 0%;
  }
`;

const Rightcontainer = styled.div`
  width: 50%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Text = styled.div`
  width: 100%;
  text-align: justify;
`;

const Titlecontainer = styled.div`
  color: ${(p) => p.co};
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 12px;
`;

const Textcontainer = styled.div`
  color: var(--gris);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
`;

const Paragraph = ({ content }) => {
  return (
    <>
      {content.map((t, index) => {
        return <Text>{t.value} </Text>;
      })}
      <br></br>
    </>
  );
};

const Historianorich = ({ titulo, text, children, fontc, fontcb }) => {
  
  return (
    <Container>
      <Subcontainer>
        <Leftcontainer>
          <Titlecontainer co={fontc}>{titulo}</Titlecontainer>
          <Textcontainer>
            {text.map((field, index) => {
              return <Paragraph content={field.content}></Paragraph>;
            })}
          </Textcontainer>
        </Leftcontainer>
        <Rightcontainer>{children}</Rightcontainer>
      </Subcontainer>
    </Container>
  );
};

export default Historianorich;
